/// UUID ///
import { v4 } from "uuid";
/// TYPES ///
import { IShopCounter } from "api/types/shop_quote/counter";
import { IShopBump } from "api/types/shop_quote/bump";
import { IShopCutShape } from "api/types/shop_quote/cut_shape";
import { IShopCutCorner } from "api/types/shop_quote/cut_corner";
import { IShopCutBump } from "api/types/shop_quote/cut_bump";
import { IShopPoint } from "api/types/shop_quote/point";
import { IShopCorner } from "api/types/shop_quote/corner";
import { IShopSeam } from "api/types/shop_quote/seam";
import { Counter } from "api/types/sketch";
import { Bump } from "api/types/sketch";
import { SeamLine } from "api/types/sketch";
import { CutShape } from "api/types/sketch";
import { CutCorner } from "api/types/sketch";
import { CutBump } from "api/types/sketch";
import { Point } from "api/types/sketch";
import { Corner } from "api/types/sketch";
import { Vector2 } from "api/types/sketch";
import { Seam } from "api/types/sketch";
/// FUNCTIONS ///
import { getCornerSeamLine } from "./seam_line";
import { getSeamLine } from "./seam_line";
import { getCenterOfCounter } from "../";
import { getPointByUuid } from "../";
/// MATH FUNCTIONS ///
import { getDistance } from "../math";
/// VALUES ///
import { SPLASH } from "values/values";
import { MITER } from "values/values";
import { WATERFALL } from "values/values";
import { STANDARD } from "values/quote";
import { FINISHED } from "values/values";
import { UNFINISHED } from "values/values";

const CUT_SHAPE_DEFAULT_POSITION: Vector2 = { X: 900, Y: 400 };

function createSplashCut(
  counter_uuid: string,
  corner_uuid: string,
  area_uuid: string,
  slab_uuid: string,
  splash_height: number,
  start: Vector2,
  end: Vector2,
  seams: Seam[]
): CutShape[] {
  const cut_shapes: CutShape[] = [];
  let prev_seam_distance: number = 0;

  for (let i = 0; i < seams?.length + 1; i++) {
    let distance: number;

    const uuid: string = v4();
    const cut_corners: CutCorner[] = [];

    if (!seams[i]) {
      const total_distance: number = Math.round(getDistance(start, end));
      distance = total_distance - prev_seam_distance;
    }
    else {
      distance = Math.round(seams[i].distance_from_point ?? 0) - prev_seam_distance;
      prev_seam_distance += distance;
    }
    cut_corners.push({
      location: start,
      uuid: v4(),
      corner_uuid: corner_uuid,
      cut_shape_uuid: uuid,
      edge_type: FINISHED,
      corner_type: STANDARD,
      corner_depth: 0,
      corner_length: 0,
      corner_radius: 0,
      cut_bumps: [],
      position: 0
    });
    cut_corners.push({
      location: {
        X: start.X + distance,
        Y: start.Y
      },
      uuid: v4(),
      corner_uuid: corner_uuid,
      cut_shape_uuid: uuid,
      edge_type: FINISHED,
      corner_type: STANDARD,
      corner_depth: 0,
      corner_length: 0,
      corner_radius: 0,
      cut_bumps: [],
      position: 1
    });
    cut_corners.push({
      location: {
        X: start.X + distance,
        Y: start.Y + splash_height * 4
      },
      uuid: v4(),
      corner_uuid: corner_uuid,
      cut_shape_uuid: uuid,
      edge_type: FINISHED,
      corner_type: STANDARD,
      corner_depth: 0,
      corner_length: 0,
      corner_radius: 0,
      cut_bumps: [],
      position: 2
    });
    cut_corners.push({
      location: {
        X: start.X,
        Y: start.Y + splash_height * 4
      },
      uuid: v4(),
      corner_uuid: corner_uuid,
      cut_shape_uuid: uuid,
      edge_type: FINISHED,
      corner_type: STANDARD,
      corner_depth: 0,
      corner_length: 0,
      corner_radius: 0,
      cut_bumps: [],
      position: 3
    });

    const cut_shape: CutShape = {
      uuid: v4(),
      counter_uuid: counter_uuid,
      slab_uuid: slab_uuid,
      cut_corners: cut_corners,
      area_uuid: area_uuid,
      fitted: false,
      rotation: 0
    }
    cut_shapes.push(cut_shape);
  }

  return cut_shapes;
}

function createSplashCuts(
  counter_uuid: string,
  area_uuid: string,
  slab_uuid: string,
  corners: Corner[],
  points: Point[],
  offset: Vector2
): CutShape[] {
  const splash_cut_shapes: CutShape[] = [];

  for (let i = 0; i < corners.length; i++) {
    if (corners[i].edge_type !== SPLASH && corners[i].edge_type !== MITER && corners[i].edge_type !== WATERFALL) {
      continue;
    }

    if (corners[i]?.splash_height <= 0) {
      continue;
    }

    let cut_start: Vector2 = {
      X: corners[i].location.X + offset.X,
      Y: corners[i].location.Y + offset.Y
    };

    const next_corner: Corner = corners[i + 1] ? corners[i + 1] : corners[0];
    const cut_end: Vector2 = {
      X: next_corner.location.X + offset.X,
      Y: next_corner.location.Y + offset.Y
    }

    let seams: Seam[];
    if (i >= corners.length / 2) {
      const point: Point = getPointByUuid(corners[i + 1]?.point_uuid ?? "", points);
      seams = point?.seams ?? [];
    }
    else {
      const point: Point = getPointByUuid(corners[i].point_uuid, points);
      seams = point?.seams ?? [];
    }

    const shapes: CutShape[] = createSplashCut(
      counter_uuid, corners[i].uuid, area_uuid, slab_uuid, corners[i].splash_height, cut_start, cut_end, seams
    );

    splash_cut_shapes.push(...shapes);
  }

  return splash_cut_shapes;
}

function createCutBumps(
  counter_uuid: string,
  cut_corner_uuid: string,
  cut_shape_uuid: string,
  bumps: Bump[]
): CutBump[] {
  const cut_bumps: CutBump[] = [];
  for (let i = 0; i < bumps.length; i++) {
    const cut_bump: CutBump = {
      bump_type: bumps[i].bump_type,
      width: bumps[i].width,
      depth: bumps[i].depth,
      distance: bumps[i].distance,
      left_side_angle: bumps[i].left_side_angle,
      right_side_angle: bumps[i].right_side_angle,
      uuid: v4(),
      counter_uuid: counter_uuid,
      cut_corner_uuid: cut_corner_uuid,
      cut_shape_uuid: cut_shape_uuid,
      bump_uuid: bumps[i].uuid,

    }
    cut_bumps.push(cut_bump);
  }
  return cut_bumps;
}

function createInitialCutCorners(
  index: number,
  index_offset: number,
  offset: Vector2,
  cut_shape: CutShape,
  corners: Corner[],
  cut_corners: CutCorner[]
) {
  const uuid: string = v4();
  const cut_bumps: CutBump[] = createCutBumps(
    corners[index].counter_uuid,
    uuid,
    cut_shape.uuid,
    corners[index].bumps
  );
  const cut_corner: CutCorner = {
    cut_shape_uuid: cut_shape.uuid,
    location: {
      X: corners[index].location.X + offset.X,
      Y: corners[index].location.Y + offset.Y
    },
    edge_type: corners[index].edge_type,
    corner_type: corners[index].corner_type,
    corner_length: corners[index].corner_length,
    corner_depth: corners[index].corner_depth,
    corner_radius: corners[index].corner_radius,
    corner_uuid: corners[index].uuid,
    uuid: uuid,
    cut_bumps: cut_bumps,
    position: 0
  };

  cut_corners.splice(index - index_offset, 0, cut_corner);

  const mirror_uuid: string = v4();
  const mirror_cut_bumps: CutBump[] = createCutBumps(
    corners[corners.length - index - 1].counter_uuid,
    cut_corner.uuid,
    cut_shape.uuid,
    corners[corners.length - index - 1].bumps
  );
  const mirror_cut_corner: CutCorner = {
    cut_shape_uuid: cut_shape.uuid,
    location: {
      X: corners[corners.length - index - 1].location.X + offset.X,
      Y: corners[corners.length - index - 1].location.Y + offset.Y
    },
    edge_type: corners[corners.length - index - 1].edge_type,
    corner_type: corners[corners.length - index - 1].corner_type,
    corner_length: corners[corners.length - index - 1].corner_length,
    corner_depth: corners[corners.length - index - 1].corner_depth,
    corner_radius: corners[corners.length - index - 1].corner_radius,
    corner_uuid: corners[corners.length - index - 1].uuid,
    uuid: mirror_uuid,
    cut_bumps: mirror_cut_bumps,
    position: 0
  };

  cut_corners.splice(index - index_offset + 1, 0, mirror_cut_corner);
}

function createSeamCorners(
  index: number,
  index_offset: number,
  offset: Vector2,
  cut_shape: CutShape,
  seam: Seam,
  seam_uuids: string[],
  point: Point,
  corners: Corner[],
  cut_corners: CutCorner[]
) {
  if (seam_uuids) {
    seam_uuids.push(seam.uuid);
  }

  let seam_line: SeamLine | null = null;

  if (seam.is_on_corner) {
    seam_line = getCornerSeamLine(corners[index], corners[corners.length - index - 1])
  }
  else {
    seam_line = getSeamLine(seam, point, corners[index]);
  }

  if (seam_line) {
    const start: CutCorner = {
      uuid: v4(),
      corner_uuid: corners[index].uuid,
      cut_shape_uuid: cut_shape.uuid,
      edge_type: UNFINISHED,
      corner_type: seam.is_on_corner ? corners[index].corner_type : STANDARD,
      corner_depth: corners[index].corner_depth,
      corner_length: corners[index].corner_length,
      corner_radius: corners[index].corner_radius,
      position: 0,
      cut_bumps: [],
      location: {
        X: seam_line.start.X + offset.X,
        Y: seam_line.start.Y + offset.Y
      },
      is_corner_seam: true,
      corner_before_seam: true
    }

    const end: CutCorner = {
      uuid: v4(),
      corner_uuid: corners[index].uuid,
      cut_shape_uuid: cut_shape.uuid,
      edge_type: corners[corners.length - index - 1].edge_type,
      corner_type: seam.is_on_corner ? corners[corners.length - index - 1].corner_type : STANDARD,
      corner_depth: corners[corners.length - index - 1].corner_depth,
      corner_length: corners[corners.length - index - 1].corner_length,
      corner_radius: corners[corners.length - index - 1].corner_radius,
      position: 0,
      cut_bumps: [],
      location: {
        X: seam_line.end.X + offset.X,
        Y: seam_line.end.Y + offset.Y
      },
      is_corner_seam: true,
      corner_before_seam: false
    }
    if (seam.is_on_corner) {
      cut_corners.splice(index - index_offset, 1, start);
      cut_corners.splice(index - index_offset + 1, 1, end);
    }
    else {
      cut_corners.splice(index - index_offset + 1, 0, start);
      cut_corners.splice(index - index_offset + 2, 0, end);
    }
  }
}

function createInitialSeamCorners(
  index: number,
  index_offset: number,
  offset: Vector2,
  cut_shape: CutShape,
  seam: Seam,
  seam_uuids: string[],
  point: Point,
  corners: Corner[],
  cut_corners: CutCorner[]
) {
  if (seam_uuids) {
    seam_uuids.push(seam.uuid);
  }

  let seam_line: SeamLine | null = null;

  if (seam.is_on_corner) {
    seam_line = getCornerSeamLine(corners[index], corners[corners.length - index - 1])
  }
  else {
    seam_line = getSeamLine(seam, point, corners[index]);
  }

  if (seam_line) {
    const start: CutCorner = {
      uuid: v4(),
      corner_uuid: corners[index].uuid,
      cut_shape_uuid: cut_shape.uuid,
      edge_type: UNFINISHED,
      corner_type: seam.is_on_corner ? corners[index].corner_type : STANDARD,
      corner_depth: corners[index].corner_depth,
      corner_length: corners[index].corner_length,
      corner_radius: corners[index].corner_radius,
      position: 0,
      cut_bumps: [],
      location: {
        X: seam_line.start.X + offset.X,
        Y: seam_line.start.Y + offset.Y
      },
      is_corner_seam: true,
      corner_before_seam: false
    }
    const end: CutCorner = {
      uuid: v4(),
      corner_uuid: corners[index].uuid,
      cut_shape_uuid: cut_shape.uuid,
      edge_type: corners[corners.length - index - 1].edge_type,
      corner_type: seam.is_on_corner ? corners[corners.length - index - 1].corner_type : STANDARD,
      corner_depth: corners[corners.length - index - 1].corner_depth,
      corner_length: corners[corners.length - index - 1].corner_length,
      corner_radius: corners[corners.length - index - 1].corner_radius,
      position: 0,
      cut_bumps: [],
      location: {
        X: seam_line.end.X + offset.X,
        Y: seam_line.end.Y + offset.Y
      },
      is_corner_seam: true,
      corner_before_seam: true
    }
    cut_corners.splice(index - index_offset, 0, start);
    cut_corners.splice(index - index_offset + 1, 0, end);
  }
}

function getOffset(offsets: Vector2[], counter: Counter): Vector2 {
  const counter_center: Vector2 = getCenterOfCounter(counter, 1);
  if (offsets.length > 0) {
    return {
      X: offsets[0].X - counter_center.X,
      Y: offsets[0].Y - counter_center.Y
    }
  }
  else {
    return {
      X: CUT_SHAPE_DEFAULT_POSITION.X - counter_center.X,
      Y: CUT_SHAPE_DEFAULT_POSITION.Y - counter_center.Y
    }
  }
}

export function createCutShapes(
  counter: Counter,
  area_uuid: string,
  slab_uuid: string,
  points: Point[],
  corners: Corner[],
  offsets: Vector2[]
): CutShape[] {
  let last_position_cutoff: number = 0;
  const cut_shapes: CutShape[] = [];
  const offset: Vector2 = getOffset(offsets, counter);

  const seam_uuids: string[] = [];

  let cut_shape: CutShape = {
    counter_uuid: counter.uuid,
    area_uuid: area_uuid,
    slab_uuid: slab_uuid,
    uuid: v4(),
    fitted: false,
    rotation: 0,
    cut_corners: []
  }

  let cut_corners: CutCorner[] = [];

  let created_initial_seams: boolean = false;

  for (let i = 0; i < points.length; i++) {
    createInitialCutCorners(i, last_position_cutoff, offset, cut_shape, corners, cut_corners);
    if (!created_initial_seams) {
      created_initial_seams = false;
    }
    const seams: Seam[] = points[i].seams.filter(function (item) {
      return seam_uuids.indexOf(item.uuid) === -1;
    });
    for (let j = 0; j < seams.length; j++) {
      seam_uuids.push(seams[j].uuid);
      createSeamCorners(
        i,
        last_position_cutoff,
        offset,
        cut_shape,
        seams[j],
        seam_uuids,
        points[i],
        corners,
        cut_corners
      );

      for (let k = 0; k < cut_corners.length; k++) {
        cut_corners[k].position = k;
      }
      cut_shape.cut_corners = cut_corners;
      cut_shapes.push(cut_shape);

      cut_corners = [];

      if (offsets.length > 0) {
        offsets.pop();
      }
      cut_shape = {
        counter_uuid: counter.uuid,
        area_uuid: area_uuid,
        slab_uuid: slab_uuid,
        uuid: v4(),
        fitted: false,
        rotation: 0,
        cut_corners: []
      }
      last_position_cutoff = i;
      createInitialSeamCorners(
        i,
        last_position_cutoff,
        offset,
        cut_shape,
        seams[j],
        [],
        points[i],
        corners,
        cut_corners
      );
      created_initial_seams = true;
    }
  }
  for (let i = 0; i < cut_corners.length; i++) {
    cut_corners[i].position = i;
  }

  cut_shapes.push({ ...cut_shape, cut_corners: cut_corners });
  const splash_cuts: CutShape[] = createSplashCuts(counter.uuid, area_uuid, slab_uuid, corners, points, offset);

  return [...cut_shapes, ...splash_cuts];
}

export function createShopCutShapes(
  counter: IShopCounter,
  area_uuid: string,
  slab_uuid: string,
  points: IShopPoint[],
  corners: IShopCorner[],
  offsets: Vector2[]
): IShopCutShape[] {
  let last_position_cutoff: number = 0;
  const cut_shapes: IShopCutShape[] = [];
  const offset: Vector2 = getOffset(offsets, counter);

  const seam_uuids: string[] = [];

  let cut_shape: IShopCutShape = {
    counter_uuid: counter.uuid,
    area_uuid: area_uuid,
    slab_uuid: slab_uuid,
    uuid: v4(),
    fitted: false,
    rotation: 0,
    cut_corners: []
  }

  let cut_corners: IShopCutCorner[] = [];

  let created_initial_seams: boolean = false;

  for (let i = 0; i < points.length; i++) {
    createInitialCutCorners(i, last_position_cutoff, offset, cut_shape, corners, cut_corners);
    if (!created_initial_seams) {
      created_initial_seams = false;
    }
    const seams: Seam[] = points[i].seams.filter(function (item) {
      return seam_uuids.indexOf(item.uuid) === -1;
    });
    for (let j = 0; j < seams.length; j++) {
      seam_uuids.push(seams[j].uuid);
      createSeamCorners(
        i,
        last_position_cutoff,
        offset,
        cut_shape,
        seams[j],
        seam_uuids,
        points[i],
        corners,
        cut_corners
      );

      for (let k = 0; k < cut_corners.length; k++) {
        cut_corners[k].position = k;
      }
      cut_shape.cut_corners = cut_corners;
      cut_shapes.push(cut_shape);

      cut_corners = [];

      if (offsets.length > 0) {
        offsets.pop();
      }
      cut_shape = {
        counter_uuid: counter.uuid,
        area_uuid: area_uuid,
        slab_uuid: slab_uuid,
        uuid: v4(),
        fitted: false,
        rotation: 0,
        cut_corners: []
      }
      last_position_cutoff = i;
      createInitialSeamCorners(
        i,
        last_position_cutoff,
        offset,
        cut_shape,
        seams[j],
        [],
        points[i],
        corners,
        cut_corners
      );
      created_initial_seams = true;
    }
  }
  for (let i = 0; i < cut_corners.length; i++) {
    cut_corners[i].position = i;
  }

  cut_shapes.push({ ...cut_shape, cut_corners: cut_corners });
  const splash_cuts: IShopCutShape[] = createSplashCuts(counter.uuid, area_uuid, slab_uuid, corners, points, offset);

  return [...cut_shapes, ...splash_cuts];
}
