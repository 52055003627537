/// REDUX ///
import { useAppDispatch } from "api/hooks/apiHook";
import { useAppSelector } from "api/hooks/apiHook";
/// REACT ///
import { useEffect } from "react";
/// ROUTER ///
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
/// ACTIONS ///
import { getFabricatorQuotes } from "api/actions/market";
import { createFabricatorQuote } from "api/actions/market";
import { getQuote } from "api/actions/quotes/quote";
import { updateLocalQuote } from "api/actions/quotes/quote";
import { setBreadcrumbs } from "api/actions/ui/set";
/// COMPONENTS ///
import BaseButton from "components/universal/button/base";
import Loading from "components/universal/loading";
import LoadingPage from "components/universal/loading_page";
import PageTitle from "components/universal/page/title";
import MarketQuoteItem from "./row/quote_item";
/// MUI COMPONENTS ///
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
/// FM ///
import { motion } from "framer-motion";
/// STYLES ///
import { content_container_style } from "styles/universal/page";
import { header_style } from "styles/universal/section_header";
import { list_container_style } from "styles/universal/page";
/// ICONS ///
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { faFileInvoiceDollar } from "@fortawesome/free-solid-svg-icons";
/// MOTION ///
import { m_init_page } from "motion/page";
import { m_anim_page } from "motion/page";
import { m_exit_page } from "motion/page";
import { page_trans } from "motion/page";
/// BREADCRUMBS ///
import { quote_list } from "values/breadcrumbs/quote";
import { quote_preview } from "values/breadcrumbs/quote";
import { quote_draw } from "values/breadcrumbs/quote";
import { market_quotes } from "values/breadcrumbs/quote";
/// URL ///
import { detail_url } from "components/navigation/endpoints";
import { quote_url } from "components/navigation/endpoints";
import { store_url } from "components/navigation/endpoints";

export default function Market() {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {
    loading,
    quotes
  } = useAppSelector((state) => state.market);
  const { quote } = useAppSelector((state) => state.quote);

  useEffect(() => {
    dispatch(getFabricatorQuotes(Number(id)));
    dispatch(getQuote(Number(id)));
  }, [dispatch, id]);

  const handleCreateFabricatorQuotes = () => {
    dispatch(createFabricatorQuote({ quote_id: id }));
    dispatch(updateLocalQuote({ ...quote, changed: false }));
  }

  useEffect(() => {
    dispatch(setBreadcrumbs([
      { ...quote_list, url: quote_list.url.replace("base_url", store_url) },
      { ...quote_preview, url: quote_preview.url.replace("base_url", store_url).replace("id", id) },
      { ...quote_draw, url: quote_draw.url.replace("base_url", store_url).replace("id", id) },
      { ...market_quotes, url: market_quotes.url.replace("id", id) }
    ]));
  }, [dispatch, id]);

  return (
    <motion.div initial={m_init_page} exit={m_exit_page} animate={m_anim_page} transition={page_trans} style={content_container_style}>
      <LoadingPage loading={!quote.id}>
        <Grid container justifyContent="space-between" sx={list_container_style}>
          <Grid item xs={12}>
            <PageTitle title="Market Quotes" endProps={
              <Box>
                <BaseButton text="Back to Quote" icon={faArrowLeft} clickEvent={() => navigate(`/${store_url}/${quote_url}/${detail_url}/${id}`)} />
                <BaseButton text="Get Market Quotes" icon={faFileInvoiceDollar} clickEvent={handleCreateFabricatorQuotes} />
              </Box>
            } />
          </Grid>
          <Grid item xs={12}>
            <Box sx={header_style}></Box>
          </Grid>
          {quotes.map((quote, index) => <MarketQuoteItem key={index} index={index} item={quote} />)}
          <Grid item xs={12}>
            <Typography variant="body2" color="error" sx={{ marginLeft: 3.5, marginBottom: quote.changed || !quote.customer ? 4 : 0 }}>
              {quote.changed ? "Quote has been changed and must be generated again before ordering" : null}{quote.changed ? <br /> : null}
              {!quote.customer ? "Must add a customer to the quote before ordering" : null}{!quote.customer ? <br /> : null}
            </Typography>
          </Grid>
        </Grid>
      </LoadingPage>
      <Loading loading={loading} />
    </motion.div >
  )
}
